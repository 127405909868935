.editor {
  border: 1px solid #ddd;
  cursor: text;
  border-radius: 5px;
  background: #fefefe;
  padding: 0.4rem 0.7rem;
  min-width: 50%;
  flex: 1 1 auto;
  height: 64px;
  position: relative !important;
  }
  .tukdd6b {
    top: -25px !important;
    border-radius: 6px !important;
  }
  .b181v2oy {
    border-radius: 6px;
  }
  /* .editor :global(.public-DraftEditor-content) {
    min-height: 140px;
  } */

  .editor .rdw-image-wrapper{
    margin-bottom: 0rem;
  }
  .editor .rdw-image-wrapper .rdw-option-wrapper{
    filter: brightness(0%);
  }
  .mini-editor-config:hover{
    box-shadow: none !important;
    border: none !important;
    padding: 5px;
  }
  .isBoxDisable {
    background-color: #f5f5f1;
  }
  .border-success {
    border: 1px solid #28c76f !important;
  }

